import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { select, Store } from '@ngxs/store';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UserService } from '../../../generated-code/role-manager-api';
import { ConfigurationSelectors } from '../../store/configuration/configuration.selectors';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzCardModule,
    NzIconModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzButtonModule,
    NzCollapseModule,
    NzModalModule,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit {
  store = inject(Store);
  user = select(ConfigurationSelectors.user);
  userAPIService = inject(UserService);
  notification = inject(NzNotificationService);
  modalService = inject(NzModalService);

  form = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
  });

  showMoreInfo = signal<boolean>(false);

  ngOnInit(): void {
    this.form.patchValue(this.user()?.user);
  }

  changePassword(): void {
    const modal = this.modalService.create({
      nzTitle: 'Change your password',
      nzContent: ChangePasswordDialogComponent,
    });
    //modal.afterClose.subscribe((result) => {});
  }

  update() {
    if (this.form.valid) {
      this.userAPIService.apiV1UserPut(this.form.value).subscribe(() => {
        this.notification.success('Success', 'User updated');
      });
    }
  }
}
