import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormControlComponent, NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import { UserService } from '../../../generated-code/role-manager-api';

@Component({
  selector: 'app-change-password-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzModalModule,
    NzButtonModule,
    NzFormControlComponent,
    NzFormModule,
    NzInputModule,
  ],
  templateUrl: './change-password-dialog.component.html',
  styleUrl: './change-password-dialog.component.scss',
})
export class ChangePasswordDialogComponent {
  private modal = inject(NzModalRef);
  private userAPIService = inject(UserService);
  form = new FormGroup({
    oldPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required]),
    newPasswordC: new FormControl('', [Validators.required]),
  });

  cancel(): void {
    this.modal.destroy();
  }

  ok(): void {
    const { oldPassword, newPassword, newPasswordC } = this.form.value;
    if (newPassword !== newPasswordC) {
      this.form.get('newPasswordC')?.setErrors({ notEqual: true });
    } else {
      this.userAPIService.apiV1UserPasswordPut({ oldPassword, newPassword }).subscribe(
        () => {
          this.modal.destroy();
        },
        (error) => {
          this.form.get('oldPassword')?.setErrors({ invalid: true });
        },
      );
    }
  }
}
