<div class="h-100 inner-y-xxs inner-x-xs">
  <div class="h-100 flex justify-content-center align-items-center">
    <div>
      <div class="flex justify-content-center align-items-center" style="width: 100vw; max-width: 800px">
        <div class="p-3">
          <span nz-icon nzType="user" nzTheme="outline" style="font-size: 10em; color: var(--clr-grey-4)"></span>
        </div>
        <div class="m-4 flex-auto">
          <form nz-form [formGroup]="form" [nzLayout]="'vertical'">
            <nz-form-item>
              <nz-form-label nzRequired nzFor="firstName">Firstname</nz-form-label>
              <nz-form-control>
                <input nz-input formControlName="firstName" id="firstName" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label nzRequired nzFor="lastName">Lastname</nz-form-label>
              <nz-form-control>
                <input nz-input formControlName="lastName" id="lastName" />
              </nz-form-control>
            </nz-form-item>
            <div class="flex mt-3">
              <button class="outer-right-nano" nz-button nzType="default" nzShape="round" (click)="changePassword()">
                <span>Change password</span>
              </button>
              <span class="flex-auto"></span>
              <button class="outer-right-nano" nz-button nzType="primary" nzShape="round" (click)="update()">
                <span>Update</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <nz-collapse nzGhost nzExpandIconPosition="end">
        <nz-collapse-panel [nzHeader]="'More info'" [nzActive]="false">
          <div class="card">
            <pre style="font-size: 0.8em; max-height: 300px; overflow: auto">{{ user() | json }}</pre>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>
</div>
