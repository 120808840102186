<div>
  <form nz-form [formGroup]="form" [nzLayout]="'vertical'">
    <nz-form-item>
      <nz-form-label nzRequired nzFor="oldPassword">Old Password</nz-form-label>
      <nz-form-control>
        <input nz-input type="password" formControlName="oldPassword" id="oldPassword" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzRequired nzFor="newPassword">New Password</nz-form-label>
      <nz-form-control>
        <input nz-input type="password" formControlName="newPassword" id="newPassword" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzRequired nzFor="newPasswordC">New Password Confirm</nz-form-label>
      <nz-form-control>
        <input nz-input type="password" formControlName="newPasswordC" id="newPasswordC" />
      </nz-form-control>
    </nz-form-item>
  </form>
</div>
<div *nzModalFooter>
  <button nz-button nzShape="round" nzType="default" (click)="cancel()">Cancel</button>
  <button nz-button nzShape="round" nzType="primary" (click)="ok()" [disabled]="!form.valid">Ok</button>
</div>
